@import "../../assets/colors.scss";
@import "../../assets/dynamic-view.scss";

.nostuff-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 95vh;
    
    .wrapper-nostuff{
        width: 80%;
        .img-nostuff{
            display: flex;
            justify-content: center;
            img{
                width: 40%;

                @include breakpoint(mobile) {
                    width: 80%;
                }
            }
        }

        .txt-nostuff{
            text-align: center;
            font-size: 1.9rem;
            font-weight: 700;
            color: $color-main;
        }
    }
}

.nostuff-cart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    
    .wrapper-nostuff{
        width: 80%;
        .img-nostuff{
            display: flex;
            justify-content: center;
        }

        .txt-nostuff{
            text-align: center;
            font-size: 1.3rem;
            font-weight: 700;
            color: $color-main;
        }
    }
}

