@import "../../assets/dynamic-view.scss";
@import "../../assets/colors.scss";

.items-container{
    background-color: $color-gray;
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    margin-top: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    
    .content{
        display: flex;
        box-sizing: border-box;
        
        .info-cart{
            flex: 4;
            display: flex;

            .img-product{
                flex: 0.7;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 60%;
                }
            }

            .info-product{
                flex: 1;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .title-product{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    margin-top: 10px;

                    .main-title{
                        font-size: 1.2rem;
                        font-weight: 700;
                        margin-bottom: 5px;
                    }
                }

                .size{
                    display: grid;
                    grid-template-columns: auto auto auto;
                    width: 100%;
                    border-radius: 8px;
                    align-items: center;
                    margin-bottom: 10px;
                    box-sizing: border-box;
                    background-color: white;
                    padding: 10px;
                    
                    .size-wrapper{
                        border: 0.9px solid;
                        display: grid;
                        text-align: center;
                        
                        .label{
                            font-size: 1rem;
                            padding-top: 3px;
                            padding-bottom: 3px;
                            font-weight: 600;
                            background-color: $color-main;
                            color: white;
                        }
                        
                        .data{
                            font-size: 1rem;
                            font-weight: 600;
                            background-color: white;
                            padding-top: 3px;
                            padding-bottom: 3px;
                        }
                    }
                }


                .price{
                    font-size: 1.1rem;
                    margin-bottom: 10px;
                    font-weight: 500;

                    span{
                        font-weight: 700;
                    }
                }

            }
        }

        .button{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            box-sizing: border-box;
            padding-left: 10px;
        }
    }
}

.item-form{
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    
    @include breakpoint(mobile){
        height: 10vh;
    }
   
    .wrapper-content{
        display: flex;
        height: 100%;
        box-sizing: border-box;
        width: 90%;
        background-color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        font-size: 1.15rem;
        font-weight: 500;
        font-family: 'Noto sans';
        padding-left: 5px;
        padding-right: 5px;

        @include breakpoint(mobile){
            font-size: 0.6rem;
            font-weight: 500;
            font-family: 'Noto sans';

        }

        /* .img{
            flex: 2;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 90%;
                max-height: 90%;
                object-fit: contain;
                border-radius: 0.5em;
            }
        } */

        .content-form{
            flex: 3;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-overflow: ellipsis;
            
            h3{
                display: flex;
                flex: 1;
                font-size: 0.8rem;
                font-weight: 700;
                align-items: center;
            }
            p{
                flex: 1;
                font-size: 0.8rem;
            }
        }

        .id-item{
            flex:1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-overflow: ellipsis;
            
            p{
                font-size: 0.85rem;
                padding: 8px;
                border-radius: 100%;
                color: white;
            }
        }
    }

    .wrapper-content:active{
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        opacity: 0.7;
    }
}