@import "../../../assets/colors.scss";
@import "../../../assets/dynamic-view.scss";

.drawer-detil{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .drawer-header{
        flex: 1;
        margin-bottom: 20px;
        text-align: center;

        // button{
        //     cursor: pointer;
        //     position: absolute;
        //     left: 10px;
        //     top: 10px;
        //     color: $color-main;
        //     background-color: transparent;
        //     border: none;
        //     font-size: 1.3rem;
        // }

        h2{
            font-size: 1.3rem;
            font-weight: 700;
        }
        
        .colorTitle{
            font-size: 1.1rem;
            font-weight: 500;
        }
    }

    .detil-content{
        flex: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: rgba(231, 231, 231, 0.692);
        border: 0.9px solid;
        padding-left: 5px;
        border-radius: 10px;

        ul{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 15px 0px 15px 0px;
            
            li{
                display: flex;
                width: 98%;
                font-family: 'Noto Sans';
                margin-top: 10px;
                justify-content: space-between;

                @include breakpoint(desktop-up) {
                    font-size: 0.9rem;
                }

                .content-label{
                    width: 25%; 
                    font-weight: 700;
                    font-size: 1rem;
                    margin-right: 5px;
                }

                .contain-detil{
                    width: 75%;
                    font-size: 0.9rem;
                    line-height: normal;
                }
            }
        }
    }
}

.drawer-page{
    width: 100%;
    box-sizing: border-box;
    
    .drawer-header{
        height: 5vh;
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding-right: 15px;

        button{
            border: none;
            flex: 0.2;
            font-size: 1.15rem;
            // color: $color-main;
            background-color: transparent;
            cursor: pointer;
        }
        
        h2{
            flex: 2;
            text-align: center;
            font-size: 1rem;
            font-family: "Noto Sans";
            font-weight: 700;
            // color: $color-main;
        }
    }
}