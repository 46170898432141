@import "../../assets/colors.scss";
@import "../../assets/dynamic-view.scss";

.exit-container{
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img-exit{
        display: flex;
        justify-content: center;
        img{
            width: 30%;

            @include breakpoint(mobile) {
                width: 50%;
            }
        }
    }

    .desc-exit{
        font-size: 1.6rem;
        font-weight: 700;
        color: $color-main;
    }

    .button{
        margin-top: 30px;
        margin-bottom: 15px;
        .endsessionbtn{
            background-color: $color-denied;
            border: none;
            color: white;
            padding: 5px 20px 5px 20px;
            font-size: 1.2rem;
            border-radius: 5px;
        }
        .endsessionbtn:active{
            opacity: 0.7;
        }
    }
}