@import "../../../assets/dynamic-view.scss";
@import "../../../assets/drawer-style.scss";
@import "../../../assets/colors.scss";

.form-container{
    font-family: 'Noto Sans';
    height: 100vh;
    max-width: 100vw;
    width: 100%;

    .navbar{
        display: flex;
        height: 5vh;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3rem;
    }

    .content{
        border-left: none;
        width: 100%;
        height: 80%;
        display: flex;
        box-sizing: border-box;
        padding: 0px 15px 0px 15px;

        @include breakpoint(mobile) {
            flex-direction: column;
            height: 100%;
            height: auto;
        }

        .carousel{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            
            @include breakpoint(desktop-up) {
                height: 100%;
            }
        }

        .detil-produk{
            flex: 1;
            height: 100%;
            display: flex;
            margin-top: 20px;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;

            @include breakpoint(desktop-up) {
                margin-left: 15px;
                margin-right: 15px;
            }
        }

        .form-input{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 10px;
            @include breakpoint(mobile){
                width: 100%;
                height: 80%;
            }

            .add-button{
                border-radius: 4px;
                border: 0;
                color: white;
                font-size: 1.5rem;
                font-weight: 700;
                width: 95%;
                padding: 5px 0px 5px 0px;
                cursor: pointer;
                margin-top: 20px
            }
        }
    }

    nav{
        height: 15%;
        display: flex;
        align-items: center;
        padding: 0px 15px 0px 15px;
        box-sizing: border-box;
        
        @include breakpoint(mobile){
            width: 100%;
        }
        
        .pagination{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .page-number{
                cursor: pointer;
                flex: 1;
                text-align: center;
            }

            .btn-page{
                flex: 1;
            }
        }
    }
    
}

.formUpdate{
    font-family: 'Noto Sans';
    margin-bottom: 20px;
    height: auto;
}

.emptyStock{
    font-family: 'Noto Sans';
    width: 100%;
    text-align: center;
    .imgEmptyStock{
        width: 90%;
    }
    
    .textEmpty{
        font-size: 1.5rem;
        font-weight: 700;
        color: $color-main;
        text-transform: uppercase;
    }
}

.search-session{
    font-family: 'Noto Sans';
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}