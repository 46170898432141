$color-btnHover:#FF6699;
$color-denied: #A90F0F;
$color-deniedActive: #940c0c;
$color-accept: #0FA958;
$color-nextbtn: #0FA958;
$color-nextBtnActive: #0d8646;
$color-editbtn: #EAAE13;
$color-editBtnActive: #b1820b;
$color-background: #F9F9F9;
$color-gray: #edede9;
$color-main: #F64F9E;
$color-btnDisable: #FF95AC;