@import "../../assets/dynamic-view.scss";
@import "../../assets/colors.scss";

.containerMainButton{
    display: flex;
    padding: 0px 2vw;
    height: 10vh;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
    width: 100%;
    cursor: pointer;

    @include breakpoint(desktop-up){
        padding: 3.5vw;
    }

    .logo-entity{
        width: 50%;
        img{
            width: 100%;
        }
        @include breakpoint(desktop-up) {
            width: 20%;
        }
    }
    
    p{
        @include breakpoint(desktop-up){
            font-size: 2em;
        }
        font-size: 1.5em;
        font-family: 'Noto Sans', sans-serif;
        font-weight: 700;
    }
}

.containerMainButton:active {
    box-shadow:  0px 1px 1px rgba(0, 0, 0, 0.25);
    opacity: 0.85;
}

.containerModButton{
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    width: 50%;
    
    @include breakpoint(mobile){
        font-size: 1.2rem;
        width:  90%;
    }
}

.add-cart{
    background-color: $color-main;
    flex: 1;
    color: white;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}