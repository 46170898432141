@import "/src/assets/colors.scss";
@import "/src/assets//dynamic-view.scss";

.formAddress-container{
    font-family: 'Noto Sans';
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .navbar {
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 5vh;
      }
    
      .wrapper-address {
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;

        .box-form {
            margin-top: 10px;
            box-sizing: content-box;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            padding: 8px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 95%;

            .title-form {
                font-size: 1.3rem;
                font-weight: 700;
                display: flex;
                align-items: center;
                margin: 8px 0px 8px 0px;
                
                span {
                  margin-left: 10px;
                }
              }

            .parent-form{
                display: flex;
                width: 100%;
                flex-direction: column;

                .content-form {
                    flex: 3;
                    padding-left: 5px;
                    padding: 10px 0px 10px 0px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
          
                    .input-form {
                      display: flex;
                      flex-direction: column;
                      box-sizing: border-box;
          
                      label {
                        flex: 1;
                        font-size: 1.15rem;
                        margin-bottom: 5px;
                      }
          
                      .btn-setAddress {
                        flex: 3;
                        justify-content: center;
                        display: flex;
          
                        button {
                          background-color: $color-main;
                          border: none;
                          cursor: pointer;
                          width: 50%;
                          color: white;
                          font-size: 1.15rem;
                          padding-top: 8px;
                          padding-bottom: 8px;
                        }
          
                        button:active {
                          background-color: $color-btnHover;
                        }
                      }
          
                      .save-address {
                        margin-top: 20px;
                      }
                    }
          
                    .input-wrapper-address {
                      flex: 3;
                      flex-direction: column;
                      width: 100%;
                    }
                  }
            }

            
      }

    }
}

.input-address {
    font-family: 'Noto Sans';
    display: flex;
    // align-items: center;
    width: 100%;
    margin-top: 10px;
    flex-direction: column;

    .quest-wrapper {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 10px;

      label {
        font-size: 1.15rem;
        width: 10px;
        flex-wrap: wrap;
      }

    }

    input{
        box-sizing: border-box;
        font-size: 1.1rem;
        padding: 3px
    }

    textarea {
        font-size: 1rem;
        font-family: "Noto Sans";
        box-sizing: border-box;
        padding: 5px;
      }

    .dropdown {
      box-sizing: border-box;
      
    }

    .inputCode{
      width: 100%;
      border: 1px solid black;
      width: 100%;
      padding: 2px 0 2px 0;
      
      ul{
        width: 100%;
        display: flex;
  
        li{
          align-items: center;
          display: flex;
          flex: 1;
          input{
            flex: 1;
            text-align: center;
            border: none;
            width: 20%;
            font-size: 1rem;
          }
          
          p{
            text-align: center;
            width: 100%;
          }
        }
      }
    }

}

.address-indo {
    font-family: 'Noto Sans';
    display: inline-flex;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;

    .segment-add-indo {
      width: 100%;
      font-size: 0.85rem;

      input {
        width: 95%;
      }

    }
}

/* .formAddress-container {

    

      .parent-form {
        display: flex;
        width: 100%;
        flex-direction: column;

        .img-form {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            border-radius: 100%;

            @include breakpoint(mobile) {
              width: 50%;
            }
          }
        }

        

        .button-container {
          display: flex;
          justify-content: center;
          @include breakpoint(mobile) {
            width: 100%;
          }

          .button-edit {
            background-color: $color-main;
            border: none;
            margin-left: 8px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            padding-top: 8px;
            padding-bottom: 8px;
            cursor: pointer;

            @include breakpoint(mobile) {
              width: 80%;
            }

            span {
              margin-left: 5px;
            }
          }

          .button-edit:active {
            background-color: $color-btnHover;
          }
        }
      }

      .parent-phone {
        width: 95%;
      }
    }
  }
} */
