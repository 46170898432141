.splashscreen{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.logout, .error-screen{
    display: flex;
    max-width: 100vw;
    max-height: 100vh;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    h4{
        font-size: 1.6rem;
        font-family: 'Noto Sans';
        font-weight: 600;
    }

    p{
        font-size: 1rem;
        font-family: 'Noto Sans';
        margin-top: 10px;
    }
}