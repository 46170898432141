@import "/src/assets/colors.scss";

.listTokoContainer{
    width: 100vw;
    box-sizing: border-box;
    font-family: 'Noto Sans';

    .navbar{
        justify-content: space-between;
        height: 5vh;
        align-items: center;
    }

    .storeWrapper{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        
        .listItemStore{
            width: 90%;
            box-sizing: border-box;
        }

        .buttonCreateStore{
            width: 90%;
            margin-top: 15px;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}

.boxItem{
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;

    .title{
        font-size: 1.3rem;
        font-weight: 700;
        display: flex;
        width: 100%;
        justify-content: space-between;
        
        .btnEdit{ 
            font-size: 1rem;
            font-weight: 400;
            background-color: $color-editbtn;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 3px;
            padding-bottom: 3px;
            border-radius: 5px;
            color: white;
            height: 20%;
        }

        .btnEdit:active {
            opacity: 0.7;
        }
    }

    .contentItemStore{
        margin-top: 10px;
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        row-gap: 8px;
    }
}

.item{
    display: flex;

    .label{
        flex: 2;
    }

    .value{
        flex: 3;
    }
}

.btnRegistStore{
    padding: 10px;
    box-sizing: border-box;
    background-color: $color-main;
    color: white;
    font-size: 1.3rem;
    font-weight: 700;
    width: 50%;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
}

.btnRegistStore:active{
    opacity: 0.8;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.emptyStoreContainer{
    width: 100%;
    img{
        width: 100%;
    }

    .contentTxt{
        text-align: center;
        margin-bottom: 20px;

        h2{
            font-size: 1.6rem;
            margin-bottom: 20px;
            font-weight: 700;
        }

        .instruction{
            font-size: 1.1rem;
            font-weight: 500;
        }
    }
}