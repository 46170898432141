@import "/src/assets/colors.scss";

.alertForm{
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    width: 80vw;
    border-radius: 8px;
    box-sizing: border-box;
    text-align: center;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Noto Sans';
    
    .title{
        font-size: 1.6rem;
        font-weight: 700;
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: white;
    }

    .content{
        width: 95%;
        font-size: 1.1rem;
        margin-top: 10px;
        margin-bottom: 10px;
        
        .contentAlert{
            font-weight: 500;
            margin-bottom: 15px;
            line-height: 1.3rem;
        }
        
    }

    .alertReguler{
        line-height: normal;
        font-size: 1.1rem;
        box-sizing: border-box;
        width: 90%;

        span{
            font-weight: 800;
        }
    }

    .handler{
        display: flex;
        justify-content: space-evenly;
        box-sizing: border-box;
        margin-top: 20px;
        width: 100%;
        
        button{
            border: none;
            cursor: pointer;
            font-size: 1.1rem;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 20px;
            color: white;
            border-radius: 8px;
        }

        button:active {
            opacity: 0.8;
        }
        
        .no{
            background-color: $color-denied;
        }

        .yes{
            background-color: $color-accept;
        }
    }

}