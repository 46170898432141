@import '../../assets/dynamic-view.scss';
@import '../../assets/colors.scss';

.quest-case{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;

    .label-quest{
        display: flex;
        justify-content: center;
        text-align: center;
        padding-left: 5px;
    }

    .input-quest{
        flex: 1;
        font-size: medium;
        text-align: center;
    }

    .priceValue{
        font-size: 1.4rem;
    }

    .stock-produk{
        text-align: center;
    }
    
    .price-produk{
        flex: 1.25;
        text-align: center;

        .discount-value{
            font-size: 0.9rem;
        }
        
        .price-ori{
            text-decoration: line-through gray;
            opacity: 0.9;
        }
    }
    .veil-wrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        
        .veil-btn{
            margin-top: 10px;
            padding: 10px;
            width: 50%;
            border-radius: 5px;
            background-color: $color-editbtn;
            text-align: center;
            color: white;
            font-size: medium;
            font-weight: 600;
            cursor: pointer;
        }

        .veil-btn:active{
            background-color: $color-editBtnActive;
        }
    }
}

.color-options{
    width: 100%;
    font-size: medium;

    .selector{
        border-radius: 5px;
        border: 1px solid black;
    }

    .selector::placeholder{
        color: black;
    }
    
    .error{
        border: 1px solid red;
        border-radius: 5px;
    }
}
