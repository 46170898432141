@import "../../assets/dynamic-view.scss";
@import "../../assets/colors.scss";

.carousel-container{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    
    .component-carousel{
        width: 80vw;
        @include breakpoint(desktop-up) {
            width: 100%;
        }
    }

    .content{
        display: flex;
        flex-direction: column;
        
        button{
            margin-top: 20px;
            font-size: 1.3rem;
            background-color: $color-editbtn;
            border: none;
            padding: 10px 20px 10px 20px;
            color: white;
            font-family: 'Noto Sans';
            font-weight: 500;
            cursor: pointer;
        }

        img{
            width: 400px;
            
            @include breakpoint(mobile){
                width: 250px;
            }
        }
    }
    
    @include breakpoint(desktop-up){
        height: 30vw;
    }

}